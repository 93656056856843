import React from "react";
import { Link } from "gatsby";
import { rhythm } from "../utils/typography";

export const Footer = () => {
  return (
    <footer
      className="footer"
      style={{ paddingTop: rhythm(0), paddingBottom: rhythm(2.5) }}
    >
      <div className="footer__content">
        <nav
          className="footer__nav"
          style={{ paddingTop: rhythm(1.25), paddingBottom: rhythm(1.25) }}
        >
          <div className="footer__nav-group footer__nav-group--bold">
            <Link to="/">Overview</Link>
            <Link to="/whitepaper">Whitepaper</Link>
            <Link to="/benefits">Benefits</Link>

            <Link to="/changelog">Changelog</Link>
            <Link to="/blog">Blog</Link>
          </div>
          <div className="footer__nav-group footer__nav-group--bold">
            <Link to="/security">Security</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/getstarted">Get started</Link>
          </div>
          <div className="footer__nav-group">
            <Link to="/contact">Contact us</Link>
            <Link to="/privacy-policy">Privacy policy</Link>
            <Link to="/terms-of-service">Terms of service</Link>
          </div>
          <div className="footer__nav-group footer__nav-social">
            <a href="https://twitter.com/RemeetHQ">
              <img
                className="no-mg-bottom"
                src={require("../../../images/Twitter_Logo_Blue.svg")}
                style={{ width: 48 }}
                alt="Twitter logo"
              ></img>
            </a>
          </div>
        </nav>
        <div className="footer__copyright">
          &copy; 2025 Meetter, Inc. &nbsp;
          <Link to="/no-cookies" className={"cookies-link"}>
            We do not use cookies on this web site
          </Link>
        </div>
      </div>
    </footer>
  );
};
